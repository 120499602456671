<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <div class="spinner" v-if="isLoading"><div><div></div></div></div>
        <!-- Header -->
        <section class="header index">
            <a class="left" @click.prevent="$router.push('/record')">
                <img class="logo" src="@/assets/img/favicon.svg">
                <span class="primary">{{ displayUserPointBalance }}</span>
            </a>
            <div class="uide-page-name">優惠地圖</div>
            <div class="right">
                <!-- <a class="icon iconButton" @click.prevent="barcodePopupOpen = true"> -->
                <a class="icon iconButton" @click.prevent="$router.push('/barcode')">                                        
                    <img  src="@/assets/img/barcode.svg">
                </a>
                <NotificationBell
                v-if="triggerCountUnread"
                @count-unread="countUnread = $event"
            />
            </div>
        </section>
        <!-- Tabs -->
        <div class="uide-tabs main-top">     
            <a class="uide-tab " @click.prevent="$router.push('/stores')">
                    <span class="fM">特店列表</span>
            </a>
                
            <a class="uide-tab active" @click.prevent="$router.push('/map')">
                    <span class="fM">特店地圖</span>
            </a>
                
        </div>
         <!-- 搜尋 -->
        <div class="uide-header-bottom-wrap">
            
            <div class="uide-filter-wrap" @click.stop>
                    <a
                      class="uide-filter"
                      @click.prevent="categoryFilterPopOpen = true"
                    >
                        <span class="">{{ filterCategory.name }}</span>
                        <div class="icon ">
                            <font-awesome-icon
                                icon="fa-solid fa-caret-down"
                                size="xs"
                            />
                        </div>
                    </a>                    
                    <a
                      class="uide-filter"
                      @click.prevent="regionFilterPopOpen = true"
                    >
                        <span v-if="filterRegion == -1" class="">全部地區</span>
                        <span v-else class="">{{ filterRegionObj.name }}</span>
                        <div class="icon ">
                            <font-awesome-icon icon="fa-solid fa-caret-down" size="xs" />
                        </div>
                    </a>
                </div>
        </div>
        <!-- Main -->
        <section class="main map no-top mt-2">
               
            <!-- Filter -->
            <div class="">
                
            </div>
            <!--vvvvvvvvvv REMOVE TEST IMAGE vvvvvvvvvv-->
            <div class="img testImg">
                <!-- <div class="search_location">
                        <input
                            type="text"
                            :placeholder="lang.curlang[0]"
                            :value="address"
                        />

                        <button
                            :disabled="dialog"
                            :loading="dialog"
                            @click="onLocationClick"
                        >
                            我的位置
                        </button>
                    </div> -->
                <devicemap
                    ref="wifimap"
                    class="wifimapcss"
                    @store-click="onStoreClick($event)"
                    @map-click="onMapClick($event)"
                    :filterRegionObj="filterRegionObj"
                    :filterCategory="filterCategory.id"
                />

                <!-- <img src="@/assets/img/map.png"> -->
            </div>
            <!--^^^^^^^^^^ REMOVE TEST IMAGE ^^^^^^^^^^-->
            <!-- 搜尋 -->
            <!-- <div class="input rounded">
                <input type="text" class="fM" placeholder="地址搜尋" value="">
                <a class="icon iconButton">
                    <font-awesome-icon icon="fas fa-arrow-right" size="lg"></font-awesome-icon>
                </a>
            </div> -->
            <div class="bottom flexV end">
                <!-- 定位 -->
                <a class="location icon iconButton white" @click.prevent="onLocationClick">
                    <font-awesome-icon icon="fa-solid fa-location-crosshairs" size="xl"></font-awesome-icon>
                </a>
                <!-- 特店資訊 -->
                <a class="information flexH width between" @click.prevent="$router.push(`/stores/${storeId}/info`)" v-show="!isHideStoreInfo">
                    <!-- class: hide -->
                    <div class="flexH">
                        <img class="store" src="@/assets/img/store.png">
                        <div class="flexV">
                            <span class="font-medium text-lg --cNeutral900">{{ storeInfo.name }}</span>
                            
                            <span class="text-lg --cNeutral500">{{
                                    storeInfo.address
                            }}</span>
                            <span class="text-base text-c01">{{ storeInfo.category }}</span>
                            <!-- <div class="detail flexH">
                                <div>
                                    <img src="@/assets/icon/point.svg">
                                    <span class="fS gray">雲林幣扭一下</span>
                                </div>
                                <div v-if="storeInfo.isDdpayEnabled">
                                    <font-awesome-icon icon="fa-solid fa-wallet"></font-awesome-icon>
                                    <span class="fS gray">行動支付</span>
                                </div>
                                <div v-if="storeInfo.isQcEnabled">
                                    <font-awesome-icon icon="fa-solid fa-utensils"></font-awesome-icon>
                                    <span class="fS gray">線上點餐</span>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="icon iconButton third"><font-awesome-icon icon="fa-solid fa-chevron-right" size="lg"></font-awesome-icon></div>
                </a>
            </div>
        </section>
        <!-- Popup (下拉選單) -->
        <FilterPopup
            v-if="categoryFilterPopOpen"
            @closePopup="categoryFilterPopOpen = false"
            v-slot="pop"
        >
            <section class="popHeader">
                <span class="fL">店家分類</span>
            </section>
            <section class="popMain" @click.prevent="pop.close">
                <a
                    @click.prevent="changeCategoryFilter({id:0, name:'全部分類'})"
                    :class="{ active: !filterCategory.id }"
                ><span>全部分類</span></a
                >
                <a
                    v-for="(category, index) in categories"
                    :key="'category_' + index"
                    :class="{ active: filterCategory.id === category.id }"
                    @click.prevent="changeCategoryFilter(category)"
                    ><span>{{ category.name }}</span></a
                >
            </section>
        </FilterPopup>
        <FilterPopup
            v-if="regionFilterPopOpen"
            @closePopup="regionFilterPopOpen = false"
            v-slot="pop"
        >
            <section class="popHeader">
                <span class="fL">地區</span>
            </section>
            <section class="popMain" @click.prevent="pop.close">
                <a
                    @click.prevent="changeRegionFilter(-1)"
                    :class="{ active: filterRegion == -1 }"
                ><span>全部地區</span></a
                >
                <a
                    v-for="(region, index) in regions"
                    :key="'region_' + index"
                    :class="{ active: filterRegion == region.id }"
                    @click.prevent="changeRegionFilter(region.id)"
                    ><span>{{ region.name }}</span></a
                >
            </section>
        </FilterPopup>
        <AlertDialog
            :title="$t('alert')"
            :content="$t('please-allow-location')"
            v-if="showAlertDialog"
            @close="() => showAlertDialog = false"
        />
    </div>
</template>

<script>
// @ is an alias to /src
import devicemap from "@/components/DeviceMap.vue";
import { mapState, mapGetters } from "vuex";
import axios from 'axios';
import NotificationBell from "@/components/NotificationBell.vue";
import FilterPopup from '@/components/FilterPopup.vue';
import AlertDialog from '@/components/Dialog/AlertDialog.vue';

export default {
    name:'AppointStoreMap',
    components: {
        devicemap,
        NotificationBell,
        FilterPopup,
        AlertDialog
    },
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            storeApiHost: process.env.VUE_APP_STORE_API_HOST,
            ecUrl: null,
            address: "",
            isLoading: false,
            dialog: false,
            isLangEn: false,
            triggerCountUnread:false,
            lang: {
                curlang: [],
                en: ["Turn on positioning permission", "Locating your address"],
                zhtw: [
                    "開啟定位查詢您的位置,點擊右方按鈕定位所在位置",
                    "正在定位您的所在位置"
                ],
                zhcn: [
                    "开启定位查询您的位置,点击右方按钮定位所在位置",
                    "正在定位您的所在位置"
                ],
                jp: [
                    "位置情報サービスをオンにして、現在地を検索してください。右側のボタンを押すと現在地を特定できます",
                    "現在地の特定"
                ],
                kr: [
                    "위치 검색을 열고 오른쪽 버튼을 클릭하여 위치를 찾습니다",
                    "위치 찾기 시작"
                ]
            },
            showAlertDialog: false,
            storeInfo: {
                id: null,
                name: "",
                address: "",
                phone: "",
                info: "",
                reset: function () {
                    this.id = null;
                    this.name = "";
                    this.address = "";
                    this.phone = "";
                    this.info = "";
                }
            },
            isHideStoreInfo: true,
            isNotificationBar: false,
            notificationBarContent: {
                title: "",
                message: "",
                time: "",
                points: "",
                reset: function () {
                    this.title = "";
                    this.message = "";
                    this.time = "";
                    this.points = "";
                }
            },
            firstNotification: {},
            countUnread: 0,
            regions: [],
            regionMap: {},
            filterRegion: -1,
            filterRegionObj: {},
            categories: [],
            filterCategory: {id: 0, name: '全部分類'},            
            categoryFilterPopOpen: false,
            regionFilterPopOpen: false,
        };
    },
   
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
            } else {
                next("/error");
            }
        });
    },
    computed: {
        ...mapState(["user"]),
        ...mapGetters({
            userPoints: "getTyUserPoints",
        }),
        storeId() {
            console.log("this.storeInfo: ", this.storeInfo)
            return this.storeInfo.id;
        },
        displayUserPointBalance() {
            return this.userPoints && this.userPoints.pointBalance
                ? this.userPoints.pointBalance
                : 0;
        },
    },
    watch: {
        filterRegion() {
            this.filterHandler();
        },
        filterCategory() {
            this.filterHandler();
        }
    },
    mounted() {
        if (this.$route.query.lang == "en") {
            this.lang.curlang = this.lang.en;
        } else if (this.$route.query.lang == "kr") {
            this.lang.curlang = this.lang.kr;
        } else if (this.$route.query.lang == "jp") {
            this.lang.curlang = this.lang.jp;
        } else if (this.$route.query.lang == "zh-cn") {
            this.lang.curlang = this.lang.zhcn;
        } else {
            this.lang.curlang = this.lang.zhtw;
        }
        this.onLocationClick();
        this.getZones();
        this.getCategories();
        this.getEcUrl();
    },
    activated() {
        this.triggerCountUnread = true;
    },
    deactivated() {
        this.triggerCountUnread = false;
        this.isLoading = false;
        this.closeFilterPop();
    },
    methods: {
        onMapClick() {
            this.isHideStoreInfo = true;
            this.storeInfo.reset();
        },
        onStoreClick(e) {
            Object.assign(this.storeInfo, e);
            this.isHideStoreInfo = false;
        },
        onLocationClick() {
            var that = this;
            if (navigator.geolocation) {
                that.dialog = true;

                let currentObj = this;

                navigator.geolocation.getCurrentPosition(
                    function (position) {
                        var pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };

                        let rGeoAPI =
                            "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                            pos.lat +
                            "," +
                            pos.lng +
                            "&key=AIzaSyDSnn4PLwW7kMPBmXkOrcLXrA4Gd-31VRU";
                        if (currentObj.$route.query.lang == "en") {
                            rGeoAPI += "&language=en";
                        }
                        axios
                            .get(rGeoAPI)
                            .then(function (response) {
                                currentObj.address =
                                    response.data.results[0].formatted_address;
                                that.dialog = false;

                                that.$refs.wifimap.initializeUserMarker(pos);
                            })
                            .catch(function (error) {
                                that.dialog = false;
                                console.log(error);
                            });
                    },
                    function (error) {
                        console.log("getCurrentPosition failure", error);
                        that.dialog = false;
                        that.lang.curlang[0] = error.message;
                        that.showAlertDialog = true;
                    }
                );
            } else {
                console.log("no navigator.geolocation");
                that.lang.curlang[0] = "no novagator.geolocation";
                that.dialog = false;
            }
        },
        getZones() {
            let config = {
                url: `${this.apiHost}/stores/v1/merchants/${this.merchantId}/portals/yunlin/zones`,
                method: "get"
            };
            console.log('query zones ', config);

            return this.$http(config)
            .then((response) => {
                console.log("zone response: ", response.data);
                this.regions = response.data;
                let regionMap = {};
                for (let r of this.regions) {
                    regionMap[r.id] = r;
                }
                this.regionMap = regionMap;
            });
            
        },
        callCategoriesAPI() {
            let config = {
                url: `${this.apiHost}/stores/v1/store-categories`,
                method: "get"
            };

            return this.$http(config)
        },
        getCategories() {
            this.callCategoriesAPI()
            .then((response) => {
                this.categories = response.data;
            });
        },
        updateRegionsHandler(e) {
            console.log("e: ", e)
            this.regions = e;
            let regionMap = {};
            for (let r of this.regions) {
                regionMap[r.id.toString()] = r;
            }
            this.regionMap = regionMap;
        },
        filterHandler() { // Device Map似乎有listen
            // console.log("this.filterRegion: ", this.filterRegion)
            // console.log('the obj check ', this.regionMap[this.filterRegion.toString()]);
            this.filterRegionObj = this.regionMap[this.filterRegion.toString()];
        },
        callGetEcUrlAPI() {
            let config = {
                url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}/app-auth/ec`,
                method: 'get',
            };
            return this.$http(config);
        },
        getEcUrl() {
            this.callGetEcUrlAPI().then((res) => {
                this.ecUrl = res.data.authUrl;
            });
        },
        goToEc() {
            this.isLoading = true;
            setTimeout(()=> {
                this.isLoading = false;
            }, 1000);
            window.location.href = this.ecUrl;
        },
        closeFilterPop() {
            this.categoryFilterPopOpen = false;
            this.regionFilterPopOpen = false;
        },
        changeCategoryFilter(category) {
            this.filterCategory = category;
        },
        changeRegionFilter(region) {
            this.filterRegion = region;
        },
    }
};
</script>

<style scoped>
.wifimapcss {
    height: 500px;
}
</style>
